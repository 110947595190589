@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";

.paginationConatiner{
  display: flex;
  justify-content: center;
}

#container {
  margin: 1rem;
}

.items {
  margin-bottom: 1rem;
}